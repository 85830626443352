import React from 'react';
import styled from 'styled-components';
import { useRecoilState } from 'recoil';
import { selectLive } from 'atoms';

const MenuTab = () => {
  const [currentMenu, setCurrentMenu] = useRecoilState(selectLive);
  const handleClick = (name) => {
    setCurrentMenu(name);
  };

  return (
    <MenuTabContainer className='border-top'>
      <MenuTabList>
        <MenuTabText
          className={currentMenu === 'm' && 'active'}
          onClick={() => handleClick('m')}
        >
          매매
        </MenuTabText>
        <MenuTabText
          className={currentMenu === 'jw' && 'active'}
          onClick={() => handleClick('jw')}
        >
          전세/월세
        </MenuTabText>
      </MenuTabList>
    </MenuTabContainer>
  );
};

const MenuTabContainer = styled.section``;
const MenuTabList = styled.ul`
  display: flex;
  justify-content: space-between;
  box-shadow: ${(props) =>
    props.className === 'active' ? '' : '0 0.125rem 0.25rem rgba(0, 0, 0, 8%)'};
`;
const MenuTabText = styled.li`
  width: 100%;
  padding: 20px;
  border-bottom: 2px solid
    ${(props) =>
      props.className === 'active' ? props.theme.$dark : props.theme.$light};
  color: ${(props) =>
    props.className === 'active' ? props.theme.$dark : props.theme.$gray600};

  font-size: 1.06em;
  font-weight: ${(props) => (props.className === 'active' ? 'bold' : '')};
  text-align: center;
  cursor: pointer;
`;

export default MenuTab;
