import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { realEstateModalState } from 'atoms';
import styled from 'styled-components';
import RealEstateMore from './RealEstateMore';
import RealEstateModal from './RealEstateModal';

const RealEstate = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isRealEstateModalOpen, setIsRealEstateModalOpen] =
    useRecoilState(realEstateModalState);

  const handleMoreButton = () => {
    setIsOpen((prev) => !prev);
  };
  return (
    data && (
      <RealEstateContainer className='px-4 pt-4'>
        <RealEstateInfoContainer>
          <RealEstateImageWrapper className='col-3'>
            <RealEstateImage src={data.fileUrl} alt='test' />
          </RealEstateImageWrapper>
          <RealEstateDescriptionContainer className='col-7'>
            <RealEstateTitle>{data.realestateName}</RealEstateTitle>
            <RealEstateOwner>대표 {data.providerName}</RealEstateOwner>
            <RealEstateAddress>{data.address}</RealEstateAddress>
          </RealEstateDescriptionContainer>
          <RealEstateMoreContainer>
            <RealEstateIcon className='i-more' onClick={handleMoreButton} />
            {isOpen && <RealEstateMore setIsOpen={setIsOpen} id={data.id} />}
          </RealEstateMoreContainer>
        </RealEstateInfoContainer>
        <RealEstateButtonContainer className='py-4'>
          <RealEstateButtonLink href={data.homepage} target='_blank'>
            <RealEstateButton>
              <RealEstateIcon className='i-board-home' />
              <span style={{ paddingLeft: '5px', color: '#888888' }}>
                홈페이지
              </span>
            </RealEstateButton>
          </RealEstateButtonLink>
          <RealEstateButtonLink href={`tel:${data.phone}`}>
            <RealEstateButton style={{ borderColor: '#368BDD' }}>
              <RealEstateIcon className='i-board-telephone-primary' />
              <span
                style={{
                  paddingLeft: '5px',
                  color: '#368BDD',
                }}
              >
                전화하기
              </span>
            </RealEstateButton>
          </RealEstateButtonLink>
        </RealEstateButtonContainer>
        {isRealEstateModalOpen ? <RealEstateModal data={data} /> : null}
      </RealEstateContainer>
    )
  );
};

const RealEstateContainer = styled.section`
  height: 100%;
  border-top: 7px solid ${(props) => props.theme.$gray100};
`;
const RealEstateInfoContainer = styled.div`
  display: flex;
`;
const RealEstateImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;
const RealEstateImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
`;
const RealEstateDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const RealEstateTitle = styled.span`
  font-weight: 700;
  font-size: 1.08rem;
  color: ${(props) => props.theme.$dark};
`;
const RealEstateOwner = styled.span`
  padding-top: 0.5rem;
  font-weight: 400;
  color: ${(props) => props.theme.$dark};
`;
const RealEstateAddress = styled.span`
  padding-top: 0.5rem;
  font-weight: 400;
  color: ${(props) => props.theme.$dark};
`;
const RealEstateMoreContainer = styled.div`
  position: absolute;
  right: 20px;
`;
const RealEstateButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const RealEstateButtonLink = styled.a`
  width: 48%;
`;
const RealEstateButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 3rem;
  border: 1px solid ${(props) => props.theme.$gray300};
  border-radius: 5px;
  color: ${(props) => props.theme.$gray500};
  cursor: pointer;
`;
const RealEstateIcon = styled.i`
  width: 11px;
  height: 11px;
`;

export default RealEstate;
