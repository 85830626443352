import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useRecoilState } from 'recoil';
import { loadingState } from 'atoms';
import { useInfiniteQuery } from 'react-query';
import { ReactComponent as JIcon } from '../assets/Icon/JIcon.svg';
import { ReactComponent as WIcon } from '../assets/Icon/WIcon.svg';

const Table = (props) => {
  const [, setLoading] = useRecoilState(loadingState);
  const { currentMenu, pyeong, currentTab } = props;
  const { data, fetchNextPage, hasNextPage, status } = useInfiniteQuery(
    ['table_data', currentMenu, pyeong, currentTab],
    async ({ pageParam = 1, year = 'all' }) => {
      const data = await fetch(
        `/comExec/realestate/?action=apt_price&type=${
          currentMenu === 'm' ? 'm' : 'jw'
        }&py=${pyeong}&year=${year}&page=${pageParam}&row_count=5`,
      ).then((res) => res.json());
      return {
        res: data,
        nextPage: pageParam + 1,
      };
    },
    {
      staleTime: 60 * 5000,
      getNextPageParam: (lastPage, pages) => lastPage.nextPage,
    },
  );

  useEffect(() => {
    status === 'loading' ? setLoading(true) : setLoading(false);
  }, [status, setLoading]);

  return (
    <TableComponent>
      <TableHeaderComponent>
        <TableFirstColumn style={{ fontWeight: 500 }}>계약일</TableFirstColumn>
        <TableSecondColumn style={{ fontWeight: 500 }}>가격</TableSecondColumn>
        <TableThirdColumn style={{ fontWeight: 500 }}>타입</TableThirdColumn>
        <TableFourthColumn style={{ fontWeight: 500 }}>동 층</TableFourthColumn>
      </TableHeaderComponent>
      {status === 'loading' ? null : status === 'error' ? (
        <p style={{ textAlign: 'center' }}>표시할 데이터가 없습니다</p>
      ) : (
        <>
          {data.pages.map((data, idx) => {
            return data.res.data.map((data) => {
              const {
                uid,
                year,
                month,
                day,
                dong,
                floor,
                type,
                money_ko,
                rent,
              } = data;

              return (
                <TableRowComponent key={uid}>
                  <TableFirstColumn>
                    {year.slice(2, 4)}-{month}-{day < 10 ? `0${day}` : day}
                  </TableFirstColumn>
                  <TableSecondColumn>
                    {currentMenu === 'jw' && rent !== '0' && <WIcon />}{' '}
                    {currentMenu === 'jw' && rent === '0' && <JIcon />}{' '}
                    {money_ko}
                    {rent && rent !== '0' && `/${rent}`}
                  </TableSecondColumn>
                  <TableThirdColumn>{type}</TableThirdColumn>
                  <TableFourthColumn>
                    {dong}동 {floor}층
                  </TableFourthColumn>
                </TableRowComponent>
              );
            });
          })}

          <AppendButton className='btn btn-block my-4' onClick={fetchNextPage}>
            더보기
          </AppendButton>
        </>
      )}
    </TableComponent>
  );
};

const TableComponent = styled.section`
  font-weight: 400 !important ;
  color: ${(props) => props.theme.$dark};
`;
const TableRowComponent = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.$gray200};
`;
const TableText = styled.span`
  text-align: center;
`;

const TableHeaderComponent = styled(TableRowComponent)`
  border: 0;
  background-color: ${(props) => props.theme.$gray100};
  text-align: center;
`;
const TableFirstColumn = styled(TableText)`
  width: 25%;
`;
const TableSecondColumn = styled(TableText)`
  width: 35%;
`;
const TableThirdColumn = styled(TableText)`
  width: 10%;
`;
const TableFourthColumn = styled(TableText)`
  width: 30%;
`;

const AppendButton = styled.button`
  border: 1px solid ${(props) => props.theme.$gray300} !important;
  color: ${(props) => props.theme.$gray600};
  font-weight: 400;
`;

export default Table;
