import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useRecoilState } from 'recoil';
import {
  selectPyeong,
  selectLive,
  modalState,
  recentDataList,
  loadingState,
  selectTab,
} from 'atoms';
import { useQuery } from 'react-query';
import { tradeListApi } from 'fetchers';

const PriceCard = () => {
  const [currentMenu, setCurrentMenu] = useRecoilState(selectLive);
  const [currentPyeong, setCurrentPyeong] = useRecoilState(selectPyeong);
  const [currentTab, setCurrentTab] = useRecoilState(selectTab);
  const [recentData, setRecentData] = useRecoilState(recentDataList);
  const [isModalOpen, setIsModalOpen] = useRecoilState(modalState);
  const [loading, setLoading] = useRecoilState(loadingState);

  const { isLoading: tradeInfoLoading, data: tradeInfoData } = useQuery(
    ['trade_data', currentMenu, currentPyeong, currentTab],
    () => {
      return currentPyeong && tradeListApi(currentMenu, currentPyeong);
    },
    {
      staleTime: 60 * 5000,
    },
  );

  useEffect(() => {
    const filteredArr = tradeInfoData?.data?.filter((data) => {
      if (data.m || data.j) {
        return data;
      }
    });

    if (filteredArr && filteredArr.length !== 0) {
      const lastArr = filteredArr && filteredArr[filteredArr?.length - 1];
      const obj = {
        year: lastArr.date.slice(2, 4),
        month: lastArr.date.slice(5, 7),
        price: lastArr.m ? lastArr.m : lastArr.j,
        cnt: lastArr.m_cnt ? lastArr.m_cnt : lastArr.j_cnt,
      };
      setRecentData(obj);
    } else {
      setRecentData('');
    }
  }, [tradeInfoData]);

  useEffect(() => {
    tradeInfoLoading ? setLoading(true) : setLoading(false);
  }, [tradeInfoLoading, setLoading]);

  const handleClick = () => {
    setIsModalOpen((prev) => !prev);
  };

  return loading ? null : (
    <PriceCardContainer className='py-3 px-4' currentMenu={currentMenu}>
      {recentData !== '' ? (
        <PriceCardDateWrapper className='my-1'>
          <PriceCardDateText className='py-1 px-2' currentMenu={currentMenu}>
            {currentMenu === 'm'
              ? `${recentData?.year}년 ${recentData?.month}월
            매매 평균`
              : `${recentData?.year}년 ${recentData?.month}월 전세 평균`}
          </PriceCardDateText>
        </PriceCardDateWrapper>
      ) : null}

      <PriceCardPriceTradeContainer
        className={recentData !== '' ? 'my-3' : 'mb-3'}
      >
        <PriceCardPriceText>
          {recentData !== ''
            ? `${recentData?.price.slice(0, -4)}억${' '} ${
                Number(recentData?.price.slice(-4)).toLocaleString() <= 0
                  ? ''
                  : Number(recentData?.price.slice(-4)).toLocaleString()
              }`
            : null}
        </PriceCardPriceText>
        <PriceCardTradeText className='mx-1'>
          {recentData !== ''
            ? `(실거래 ${recentData?.cnt}건)`
            : '거래내역이 없습니다'}
        </PriceCardTradeText>
      </PriceCardPriceTradeContainer>
      <PriceCardAreaSelectContainer>
        <PriceCardAreaSelect
          className={
            currentMenu === 'm'
              ? 'btn btn-primary my-2'
              : 'btn btn-success my-2'
          }
          currentMenu={currentMenu}
          onClick={handleClick}
        >
          {currentPyeong}평
        </PriceCardAreaSelect>
        <PriceCardIcon
          className='i-chevronDown'
          style={{ position: 'absolute', bottom: '45%', right: '5%' }}
        />
      </PriceCardAreaSelectContainer>
    </PriceCardContainer>
  );
};

const PriceCardContainer = styled.section`
  display: flex;
  flex-direction: column;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) =>
    props.currentMenu === 'm' ? props.theme.$primary : props.theme.$success};
  border-radius: 5px;
`;
const PriceCardDateWrapper = styled.section``;
const PriceCardDateText = styled.span`
  border-radius: 20px;
  background-color: ${(props) =>
    props.currentMenu === 'm'
      ? props.theme.$primaryLight
      : props.theme.$successLight};
  font-size: 0.8rem;
  font-weight: 500;
  color: ${(props) =>
    props.currentMenu === 'm' ? props.theme.$primary : props.theme.$success};
`;
const PriceCardPriceTradeContainer = styled.section``;
const PriceCardPriceText = styled.span`
  font-size: 1.8rem;
  font-weight: bold;
`;
const PriceCardTradeText = styled.span`
  color: ${(props) => props.theme.$gray500};
`;

const PriceCardAreaSelectContainer = styled.section`
  position: relative;
`;
const PriceCardAreaSelect = styled.button`
  width: 100%;
  color: ${(props) => props.theme.$white} !important;
  font-weight: bold;
`;
const PriceCardIcon = styled.i``;

export default PriceCard;
