import React from 'react';
import styled from 'styled-components';
import { useRecoilState } from 'recoil';
import { currentRealEstateData, realEstateModalState } from 'atoms';
import { realEstate } from 'fetchers';

const RealEstateMore = ({ setIsOpen, id }) => {
  const [realEstateData, setRealEstateData] = useRecoilState(
    currentRealEstateData,
  );
  const [isRealEstateModalOpen, setIsRealEstateModalOpen] =
    useRecoilState(realEstateModalState);

  const handleModifyButton = () => {
    setIsOpen((prev) => !prev);
    setIsRealEstateModalOpen((prev) => !prev);
  };

  const handleResetButton = async () => {
    setIsOpen((prev) => !prev);

    const remainData = realEstateData.providers.filter(
      (data) => data.id !== id,
    );

    setRealEstateData(remainData);

    const response = await realEstate.removeData(id);
    if (response) {
      alert('초기화가 완료되었습니다');
    }
    window.location.reload();
  };

  return (
    <RealEstateMoreContainer className='d-flex flex-column justify-content-center shadow-sm border-gray-200'>
      <RealEstateMoreTextWrapper className='py-1' onClick={handleModifyButton}>
        <RealEstateMoreText>수정</RealEstateMoreText>
      </RealEstateMoreTextWrapper>
      <RealEstateMoreTextWrapper className='py-1' onClick={handleResetButton}>
        <RealEstateMoreText>초기화</RealEstateMoreText>
      </RealEstateMoreTextWrapper>
    </RealEstateMoreContainer>
  );
};

const RealEstateMoreContainer = styled.div`
  position: absolute;
  width: 121px;
  height: 92px;
  top: 35px;
  right: 10px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  background-color: white;
`;

const RealEstateMoreTextWrapper = styled.div``;
const RealEstateMoreText = styled.h5`
  color: #333333;
  font-size: 15px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
`;

export default RealEstateMore;
