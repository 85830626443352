import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { modalState, selectPyeong } from 'atoms';
import { useRecoilState } from 'recoil';

const Modal = ({ data: aptData }) => {
  const [windowWidth, setWindowWidth] = useState();
  const [isModalOpen, setIsModalOpen] = useRecoilState(modalState);
  const [pyeong, setPyeong] = useRecoilState(selectPyeong);

  const handleModal = () => {
    setIsModalOpen((prev) => !prev);
    document.body.style.overflow = 'unset';
  };
  const handleClick = (e) => {
    if (e.target.dataset.name) {
      setPyeong(e.target.dataset.name);
    } else {
      setPyeong(e.target.parentNode.dataset.name);
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
    }
  }, [isModalOpen]);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  return (
    <ModalComponent className='fixed-bottom' onClick={handleModal}>
      <ModalInfoComponent className='fixed-bottom'>
        <ModalInfoTitleComponent>
          <ModalInfoTitle>평형</ModalInfoTitle>
          <ModalInfoCloseButton>
            <ModalIcon className='i-close' />
          </ModalInfoCloseButton>
        </ModalInfoTitleComponent>
        <ModalInfoList>
          {aptData.map((data) => (
            <ModalInfoListItem key={data.pyeong}>
              <ModalInfoListItemTextContainer
                onClick={handleClick}
                style={{ color: data.pyeong === pyeong && 'black' }}
                data-name={data.pyeong}
              >
                <ModalInfoListItemText>{data.pyeong}평</ModalInfoListItemText>
                <ModalInfoListItemText>
                  {data.household}세대
                </ModalInfoListItemText>
                {windowWidth <= 320 ? (
                  <ModalInfoListItemText>
                    공급 {data.g_area}㎡ {'\n'} 전용 {data.j_area}㎡
                  </ModalInfoListItemText>
                ) : (
                  <>
                    <ModalInfoListItemText>
                      공급 {data.g_area}㎡
                    </ModalInfoListItemText>
                    <ModalInfoListItemText>
                      전용 {data.j_area}㎡
                    </ModalInfoListItemText>
                  </>
                )}

                <ModalInfoListItemText>
                  {data.pyeong === pyeong ? (
                    <ModalIcon className='i-check' />
                  ) : (
                    <div> </div>
                  )}
                </ModalInfoListItemText>
              </ModalInfoListItemTextContainer>
            </ModalInfoListItem>
          ))}
        </ModalInfoList>
      </ModalInfoComponent>
    </ModalComponent>
  );
};

const ModalComponent = styled.section`
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1004;
`;

const ModalInfoComponent = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 30%;
  max-height: 50%;
  border-radius: 0.3rem 0.3rem 0 0;
  background-color: white;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;

const ModalInfoTitleComponent = styled.div`
  height: 7vmax;
  border-radius: 30px 30px 0 0;
  padding: 0 0 0 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: ${(props) => props.theme.$white};
  z-index: 2;
`;
const ModalInfoTitle = styled.h3`
  position: absolute;
  right: 45%;
  font-size: 1.2rem;
  font-weight: 700;
`;
const ModalInfoCloseButton = styled.div`
  padding-right: 1rem;
  cursor: pointer;
`;
const ModalInfoList = styled.ul`
  width: 100%;
  min-height: 20%;
`;
const ModalInfoListItem = styled.li`
  display: block;
  border-top: 1px solid ${(props) => props.theme.$gray200};
`;
const ModalInfoListItemTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 1.2rem 0 1.2rem 1.2rem;
  color: ${(props) => props.theme.$gray500};

  @media screen and (max-width: 320px) {
    padding: 0.8rem 0 0.8rem 1.2rem;
  }
`;
const ModalInfoListItemText = styled.span`
  display: block;
  margin: 0 auto;
  font-size: 0.9rem;
  white-space: pre-line;

  :first-child {
    width: 15%;
    font-weight: bold;
  }
  :nth-child(2) {
    width: 20%;
  }
  :nth-child(3) {
    width: 25%;
  }
  :nth-child(4) {
    width: 25%;
  }
  :last-child {
    width: 5%;
  }

  @media screen and (max-width: 320px) {
    :first-child {
      width: 15%;
      font-weight: bold;
    }
    :nth-child(2) {
      width: 20%;
    }
    :nth-child(3) {
      width: 30%;
    }
    :nth-child(4) {
      width: 25%;
    }
    :last-child {
      width: 5%;
    }

    :nth-child(3) {
      line-height: 20px;
    }
  }

  @media screen and (max-width: 300px) {
    font-size: 0.75rem;
  }
`;

const ModalIcon = styled.i``;

export default Modal;
