import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

const GlobalStyle = createGlobalStyle`
  ${reset}
  box-sizing: border-box;

  body {
    font-family: Pretendard;
    font-size: 15px;
  }
`;

export default GlobalStyle;
