import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { loadingState, selectLive, selectPyeong, selectTab } from 'atoms';
import { useRecoilState } from 'recoil';
import { useQuery } from 'react-query';
import { tradeListApi } from 'fetchers';
import MixedCharts from './Chart/MixedChart/MixedChart';
import MultiAxis from './Chart/MultiAxis/MultiAxis';
import Table from './Table';

const ChartData = () => {
  const [currentMenu, setCurrentMenu] = useRecoilState(selectLive);
  const [currentPyeong, setCurrentPyeong] = useRecoilState(selectPyeong);
  const [loading, setLoading] = useRecoilState(loadingState);
  const [currentTab, setCurrentTab] = useRecoilState(selectTab);
  const [multiAxisData, setMultiAxisData] = useState([]);
  const [mixedChartData, setMixedChartData] = useState([]);

  const { isLoading: tradeInfoLoading, data: tradeInfoData } = useQuery(
    ['trade_data', currentMenu, currentPyeong, currentTab],
    () => {
      return currentPyeong && tradeListApi(currentMenu, currentPyeong);
    },
    {
      staleTime: 60 * 5000,
    },
  );

  useEffect(() => {
    tradeInfoLoading ? setLoading(true) : setLoading(false);
  }, [tradeInfoLoading, setLoading]);

  useEffect(() => {
    if (currentMenu === 'm') {
      const length = tradeInfoData?.data?.length;
      const mPriceArr = [];
      const mTradeArr = [];
      tradeInfoData?.data?.forEach((data, idx) => {
        const { date, m, m_cnt } = data;
        if (currentTab === 'recent') {
          if (idx > length - 37) {
            m !== '' &&
              mPriceArr.push({
                x: date,
                y: m && (m / 10000).toFixed(1),
              });
            m_cnt !== '0' &&
              mTradeArr.push({
                x: date,
                y: m_cnt,
              });
          }
        } else if (currentTab === 'all') {
          m !== '' &&
            mPriceArr.push({
              x: date,
              y: m && (m / 10000).toFixed(1),
            });
          m_cnt !== '0' &&
            mTradeArr.push({
              x: date,
              y: m_cnt,
            });
        }
      });
      setMultiAxisData({
        ...multiAxisData,
        mPriceArr,
        mTradeArr,
      });
    } else if (currentMenu === 'jw') {
      const length = tradeInfoData?.data?.length;
      const jPriceArr = [];
      const jTradeArr = [];
      const wTradeArr = [];
      tradeInfoData?.data?.forEach((data, idx) => {
        const { date, j, j_cnt, w_cnt } = data;
        if (currentTab === 'recent') {
          if (idx + 1 > length - 74) {
            if (idx % 2 === 0) {
              j &&
                jPriceArr.push({
                  x: date,
                  y: j && (j / 10000).toFixed(1),
                });
              j_cnt &&
                jTradeArr.push({
                  x: date,
                  y: j_cnt,
                });
            } else {
              w_cnt &&
                wTradeArr.push({
                  x: date,
                  y: w_cnt,
                });
            }
          }
        } else if (currentTab === 'all') {
          if (idx % 2 === 0) {
            j !== '' &&
              jPriceArr.push({
                x: date,
                y: j && (j / 10000).toFixed(1),
              });
            j_cnt !== '0' &&
              jTradeArr.push({
                x: date,
                y: j_cnt,
              });
          } else {
            w_cnt !== '0' &&
              wTradeArr.push({
                x: date,
                y: w_cnt,
              });
          }
        }
      });
      setMixedChartData({
        ...mixedChartData,
        jPriceArr,
        jTradeArr,
        wTradeArr,
      });
    }
  }, [tradeInfoData]);

  const handleClick = (name) => {
    setCurrentTab(name);
  };

  return (
    <ChartDataContainer className='d-flex flex-column'>
      <ChartDataTabsContainer>
        <ChartDataTabsText
          state={currentTab === 'recent'}
          onClick={() => handleClick('recent')}
        >
          최근 3년
        </ChartDataTabsText>
        <ChartDataTabsText
          state={currentTab === 'all'}
          onClick={() => handleClick('all')}
        >
          전체 기간
        </ChartDataTabsText>
      </ChartDataTabsContainer>
      <ChartDataChartWrapper className='pt-4 mb-4'>
        {tradeInfoLoading ? (
          <div style={{ height: '100vmin' }}></div>
        ) : currentMenu === 'm' ? (
          <MultiAxis data={multiAxisData} />
        ) : (
          <MixedCharts data={mixedChartData} />
        )}
      </ChartDataChartWrapper>
      <ChartDataTableWrapper className='px-4'>
        {tradeInfoLoading ? null : (
          <Table
            currentTab={currentTab}
            pyeong={currentPyeong}
            currentMenu={currentMenu}
          />
        )}
      </ChartDataTableWrapper>
    </ChartDataContainer>
  );
};

const ChartDataContainer = styled.section`
  border-top: 7px solid ${(props) => props.theme.$gray100};
`;
const ChartDataTabsContainer = styled.article`
  padding: 2rem 1rem 0 1.5rem;
`;
const ChartDataTabsText = styled.span`
  line-height: 19.2px;
  font-size: 1.06rem;
  font-weight: 700;
  color: ${(props) => (props.state ? props.theme.$dark : props.theme.$gray500)};
  :last-child {
    padding-left: 1rem;
  }
`;
const ChartDataChartWrapper = styled.article`
  height: 40vh;
`;
const ChartDataTableWrapper = styled.article``;

export default ChartData;
