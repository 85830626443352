import styled from 'styled-components';

export const Loader = () => {
  return (
    <LoaderWrapper>
      <div className='spinner-border text-primary' role='status'>
        <span className='sr-only'>로딩중..</span>
      </div>
    </LoaderWrapper>
  );
};

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1001;
`;
