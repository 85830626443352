import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import './MultiAxis.css';

const MultiAxis = ({ data }) => {
  const { mPriceArr, mTradeArr } = data;

  const series = [
    {
      name: '매매평균',
      type: 'line',
      data: mPriceArr,
    },
    {
      name: '거래건수',
      type: 'column',
      data: mTradeArr,
    },
  ];

  const options = {
    chart: {
      type: 'line',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      events: {
        click: () => {},
      },
      offsetY: -20,
    },
    colors: ['#17A2B8', 'rgba(54, 139, 221, 0.2)'],
    stroke: {
      width: [3, 0],
      curve: 'smooth',
    },
    grid: {
      padding: {
        top: 20,
      },
    },
    xaxis: {
      type: 'datetime',
      labels: {
        show: true,
        datetimeFormatter: {
          year: `yy년`,
          month: `MM월`,
          day: 'MM월dd일',
        },
        style: {
          fontSize: '13px',
          fontWeight: 400,
        },
      },
      tooltip: {
        enabled: false,
      },
      axisTicks: {
        show: false,
      },
      tickPlacement: 'on',
    },
    yaxis: [
      {
        labels: {
          show: true,
          formatter: (val) => {
            return `${val.toFixed(1)}억`;
          },
          style: {
            fontSize: '13px',
            fontWeight: 400,
          },
        },
        tickAmount: 4,
      },
      {
        labels: {
          show: true,
          formatter: (val) => {
            if (val > 0) {
              return `${val.toFixed(0)}건`;
            }
          },
          style: {
            fontSize: '13px',
            fontWeight: 400,
          },
        },
        opposite: true,
      },
    ],
    plotOptions: {
      bar: {
        columnWidth: '30%',
      },
    },
    tooltip: {
      custom: ({ series, seriesIndex, dataPointIndex, w }) => {
        return `
          <div class='tooltip-component'>
            <span class="tooptip-text">${w.globals.initialSeries[
              seriesIndex
            ].data[dataPointIndex].x.slice(2, 4)}.${w.globals.initialSeries[
          seriesIndex
        ].data[dataPointIndex].x.slice(5, 7)}&nbsp;</span>
            
        ${
          series[0][dataPointIndex]
            ? ` 
          <span class="tooptip-text">평균 ${series[0][dataPointIndex]}억 &nbsp;</span>
          <span class="tooptip-text">(${series[1][dataPointIndex]}건)</span>
          `
            : `
          <span class="tooptip-text">월세</span>
          <span class="tooptip-text">(${series[2][dataPointIndex]}건)</span>
           `
        }
            
        `;
      },
      followCursor: true,
    },
    markers: {
      hover: {
        size: 5,
        sizeOffset: 5,
      },
    },
    noData: {
      text: '표시할 데이터가 없습니다',
    },
    legend: {
      fontSize: '13px',
      show: true,
      position: 'top',
      horizontalAlign: 'left',
      itemMargin: {
        horizontal: 12,
      },
      offsetX: -20,
      offsetY: 17,
      markers: {
        width: 8,
        height: 8,
        offsetX: -2,
      },
      labels: {
        colors: '#888888',
      },
    },
  };

  return <ReactApexChart options={options} series={series} height='100%' />;
};

export default MultiAxis;
