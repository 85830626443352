import React from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { useRecoilState } from 'recoil';
import { selectPyeong, aptInfoDataList, loadingState, modalState } from 'atoms';
import { aptInformationApi } from 'fetchers';
import ChartData from 'components/ChartData';
import MenuTab from 'components/MenuTab';
import PriceCard from 'components/PriceCard';
import RealEstate from 'components/RealEstate';
import { Loader } from 'components/Loader';
import Modal from './components/Modal';

import { realEstate } from 'fetchers';
import { currentRealEstateData } from 'atoms';

const Main = () => {
  const [pyeong, setPyeong] = useRecoilState(selectPyeong);
  const [loading, setLoading] = useRecoilState(loadingState);
  const [isModalOpen, setIsModalOpen] = useRecoilState(modalState);

  const [aptInfoData, setAptInfoData] = useRecoilState(aptInfoDataList);
  const [realEstateData, setRealEstateData] = useRecoilState(
    currentRealEstateData,
  );

  const { isLoading, data } = useQuery(
    ['main_data'],
    () => aptInformationApi(),
    {
      onSuccess: (data) => {
        data?.data[0]?.area_type.sort((a, b) => a.pyeong - b.pyeong);
        setPyeong(data.data[0].default_pyeong);
        setAptInfoData(data);
      },
    },
  );

  const { isLoading: fetchedRealEstateLoading, data: fetchedRealEstateData } =
    useQuery(['realEstate'], () => realEstate.getData(), {
      staleTime: 60 * 5000,
      onSuccess: (data) => setRealEstateData(data),
    });

  console.log(realEstateData);

  return (
    !isLoading && (
      <MainContainer className='d-flex flex-column'>
        <MainTitleWrapper className='container-fluid my-3'>
          <div className='row'>
            <div className='col-12 mt-3'>
              <MainTitleText className='font-weight-bolder'>
                부동산정보
              </MainTitleText>
            </div>
          </div>
        </MainTitleWrapper>
        <MenuTabWrapper>
          <MenuTab />
        </MenuTabWrapper>
        <PriceCardWrapper className='my-2 px-3 py-3'>
          <PriceCard />
        </PriceCardWrapper>
        <ChartDataContainer>
          <ChartData />
        </ChartDataContainer>
        {isModalOpen ? <Modal data={aptInfoData.data[0].area_type} /> : null}

        <RealEstateWrapper>
          {!fetchedRealEstateLoading &&
            realEstateData?.providers?.map((data) => (
              <RealEstate data={data} key={data.id} />
            ))}
        </RealEstateWrapper>

        {loading ? <Loader /> : null}
      </MainContainer>
    )
  );
};

const MainContainer = styled.section`
  width: 100%;
`;
const MainTitleWrapper = styled.article``;
const MainTitleText = styled.h1`
  font-size: 1.6rem;
`;
const MenuTabWrapper = styled.article``;
const PriceCardWrapper = styled.article``;
const ChartDataContainer = styled.article``;
const RealEstateWrapper = styled.article``;

export default Main;
