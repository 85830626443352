import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { useRecoilState } from 'recoil';
import { realEstateModalState } from 'atoms';
import { ReactComponent as Close } from '../assets/Icon/Close.svg';
import { realEstate } from 'fetchers';

const RealEstateModal = ({ data }) => {
  const [isRealEstateModalOpen, setIsRealEstateModalOpen] =
    useRecoilState(realEstateModalState);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(data.fileUrl);
  const [file, setFiles] = useState([]);
  const [isAttatch, setIsAttach] = useState(false);
  const inputFileRef = useRef();

  const handleAddPhoto = () => {
    inputFileRef.current.click();
  };

  const handleRemovePhoto = () => {
    setImagePreviewUrl(
      'https://resource.aptstory.com/images/content/img-empty-realestate.svg',
    );
    inputFileRef.current.value = '';
    setIsAttach((prev) => !prev);
  };

  const uploadFileHandler = (e) => {
    const reader = new FileReader();
    const imageFile = e.target.files[0];
    reader.onloadend = () => {
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(imageFile);
    setFiles(imageFile);
    setIsAttach(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('file', file);
    formData.append('action', 'update');
    formData.append('id', data.id);
    formData.append('realestateName', e.target[1].value);
    formData.append('providerName', e.target[2].value);
    formData.append('address', e.target[3].value);
    formData.append('homepage', e.target[4].value);
    formData.append('phone', e.target[5].value);

    const response = await realEstate.modifyData({
      formData,
    });
  };

  return (
    <RealEstateModalComponent className='fixed-bottom'>
      <RealEstateModalRegisterComponent className='fixed-bottom'>
        <RealEstateModalRegisterTitleComponent className='py-4 mx-3'>
          <RealEstateModalRegisterTitle>업체정보</RealEstateModalRegisterTitle>
          <RealEstateModalRegisterCloseButton
            onClick={() => setIsRealEstateModalOpen(false)}
          >
            <Close />
          </RealEstateModalRegisterCloseButton>
        </RealEstateModalRegisterTitleComponent>
        <RealEstateModalRegisterList
          className='d-flex flex-column'
          onSubmit={handleSubmit}
        >
          <RealEstateModalRegisterInputContainer className='d-flex flex-row align-items-center justify-content-center py-4'>
            <RealEstateModalRegisterFileUploadWrapper className='d-flex flex-row align-items-center justify-content-center'>
              <RealEstateModalRegisterFileUploadImage
                src={
                  imagePreviewUrl
                    ? imagePreviewUrl
                    : 'https://resource.aptstory.com/images/content/img-empty-realestate.svg'
                }
              />
              {!isAttatch ? (
                <RealEstateModalRegisterFileUploadIcon
                  className='i-photo-add'
                  onClick={handleAddPhoto}
                />
              ) : (
                <RealEstateModalRegisterFileUploadIcon
                  className='i-photo-delete'
                  onClick={handleRemovePhoto}
                />
              )}
              <RealEstateModalRegisterFileUploadInput
                hidden
                type='file'
                ref={inputFileRef}
                onChange={uploadFileHandler}
              />
            </RealEstateModalRegisterFileUploadWrapper>
          </RealEstateModalRegisterInputContainer>
          <RealEstateModalRegisterInputContainer className='d-flex flex-row align-items-center py-2 px-3'>
            <RealEstateModalRegisterInputTitle className='col-3 pl-0'>
              업체명
            </RealEstateModalRegisterInputTitle>
            <RealEstateModalRegisterInput
              className='pl-2'
              placeholder='예)OOO부동산'
              defaultValue={data.realestateName}
            />
          </RealEstateModalRegisterInputContainer>
          <RealEstateModalRegisterInputContainer className='d-flex flex-row align-items-center py-2 px-3'>
            <RealEstateModalRegisterInputTitle className='col-3 pl-0'>
              대표자명
            </RealEstateModalRegisterInputTitle>
            <RealEstateModalRegisterInput
              className='pl-2'
              placeholder='예) 김동글'
              defaultValue={data.providerName}
            />
          </RealEstateModalRegisterInputContainer>
          <RealEstateModalRegisterInputContainer className='d-flex flex-row align-items-center py-2 px-3'>
            <RealEstateModalRegisterInputTitle className='col-3 pl-0'>
              업체주소
            </RealEstateModalRegisterInputTitle>
            <RealEstateModalRegisterInput
              className='pl-2'
              defaultValue={data.address}
            />
          </RealEstateModalRegisterInputContainer>
          <RealEstateModalRegisterInputContainer className='d-flex flex-row align-items-center py-2 px-3'>
            <RealEstateModalRegisterInputTitle className='col-3 pl-0'>
              홈페이지
            </RealEstateModalRegisterInputTitle>
            <RealEstateModalRegisterInput
              className='pl-2'
              defaultValue={data.homepage}
            />
          </RealEstateModalRegisterInputContainer>
          <RealEstateModalRegisterInputContainer className='d-flex flex-row align-items-center py-2 px-3'>
            <RealEstateModalRegisterInputTitle className='col-3 pl-0'>
              전화번호
            </RealEstateModalRegisterInputTitle>
            <RealEstateModalRegisterInput
              className='pl-2'
              defaultValue={data.phone}
            />
          </RealEstateModalRegisterInputContainer>
          <RealEstateModalRegisterButtonContainer>
            <RealEstateModalRegisterCancelButton
              onClick={() => setIsRealEstateModalOpen(false)}
            >
              닫기
            </RealEstateModalRegisterCancelButton>
            <RealEstateModalRegisterConfirmButton>
              저장
            </RealEstateModalRegisterConfirmButton>
          </RealEstateModalRegisterButtonContainer>
        </RealEstateModalRegisterList>
      </RealEstateModalRegisterComponent>
    </RealEstateModalComponent>
  );
};

const RealEstateModalComponent = styled.section`
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;

const RealEstateModalRegisterComponent = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 30%;
  max-height: 50%;
  border-radius: 0.3rem 0.3rem 0 0;
  background-color: white;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;

const RealEstateModalRegisterTitleComponent = styled.div`
  border-bottom: 2px solid black;
  border-radius: 30px 30px 0 0;
  padding: 0 0 0 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: ${(props) => props.theme.$white};
  z-index: 2;
`;
const RealEstateModalRegisterTitle = styled.h3`
  position: absolute;
  right: 42%;
  font-size: 1.2rem;
  font-weight: 700;
`;
const RealEstateModalRegisterCloseButton = styled.div`
  padding-right: 1rem;
  cursor: pointer;
`;
const RealEstateModalRegisterList = styled.form``;
const RealEstateModalRegisterInputContainer = styled.div`
  :nth-child(1) {
    height: 100px;
  }

  :not(:nth-child(6)) {
    border-bottom: 1px solid #ededed;
  }
`;
const RealEstateModalRegisterInputTitle = styled.h5`
  color: #888888;
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
`;
const RealEstateModalRegisterInput = styled.input`
  width: 100%;
  height: 48.5px;
  background: #ffffff;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 5px;

  ::placeholder {
    color: #dddddd;
  }
`;
const RealEstateModalRegisterFileUploadWrapper = styled.div`
  position: absolute;
  width: 60px;
  height: 60px;
  background: #f7f7f7;
  border-radius: 50%;
`;
const RealEstateModalRegisterFileUploadImage = styled.img`
  position: relative;
  left: 10%;
  width: 60px;
  height: 60px;
  border-radius: 50%;
`;
const RealEstateModalRegisterFileUploadIcon = styled.i`
  position: relative;
  top: 31.6%;
  right: 17.5%;
`;
const RealEstateModalRegisterFileUploadInput = styled.input`
  all: unset;
  width: 100%;
`;

const RealEstateModalRegisterButtonContainer = styled.div``;
const RealEstateModalRegisterCancelButton = styled.button`
  all: unset;
  width: 30%;
  height: 64.85px;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  color: #aaaaaa;
  background-color: #f7f7f7;
  cursor: pointer;
`;
const RealEstateModalRegisterConfirmButton = styled.button`
  all: unset;
  width: 70%;
  height: 64.85px;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  background-color: ${(props) => props.theme.$primary};
  color: white;
  cursor: pointer;
`;

export default RealEstateModal;
