const size = {
  mobile: '600px',
  tablet: '900px',
  laptop: '1200px',
  desktop: '1800px',
};

const theme = {
  $primary: '#368bdd',
  $success: '#28a745',
  $danger: '#f24147',
  $warning: '#efaa2d',
  $white: '#ffffff',
  $dark: '#333333',
  $light: '#ededed',
  $primaryLight: '#e1eefa',
  $secondaryLight: '#dddddd',
  $successLight: '#e1fae3',
  $dangerLight: '#ffeced',
  $warningLight: '#fcf1dd',
  $blugLight: '#f5f9fd',
  $gray100: '#f7f7f7',
  $gray200: '#ededed',
  $gray300: '#dddddd',
  $gray500: '#aaaaaa',
  $gray600: '#888888',
  $gray800: '#333333',

  $mobile: `(max-width: ${size.mobile})`,
  $tablet: `(max-width: ${size.tablet})`,
  $laptop: `(max-width: ${size.laptop})`,
  $desktop: `(min-width: ${size.desktop})`,
};

export default theme;
