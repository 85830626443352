import React from 'react';
import ReactApexChart from 'react-apexcharts';
import './MixedChart.css';

const MixedCharts = (data) => {
  const { jPriceArr, jTradeArr, wTradeArr } = data.data;
  const innerWidth = window.innerWidth;

  const series = [
    {
      name: '전세평균',
      type: 'line',
      data: jPriceArr,
    },
    {
      name: '전세 거래수',
      type: 'column',
      data: jTradeArr,
    },
    {
      name: '월세 거래수',
      type: 'column',
      data: wTradeArr,
    },
  ];
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
      fontFamily: 'Pretendard',
      defaultLocale: 'ko',
      locales: [
        {
          name: 'ko',
          options: {
            months: [
              '1월',
              '2월',
              '3월',
              '4월',
              '5월',
              '6월',
              '7월',
              '8월',
              '9월',
              '10월',
              '11월',
              '12월',
            ],
            shortMonths: [
              '1월',
              '2월',
              '3월',
              '4월',
              '5월',
              '6월',
              '7월',
              '8월',
              '9월',
              '10월',
              '11월',
              '12월',
            ],
            days: [
              '일요일',
              '월요일',
              '화요일',
              '수요일',
              '목요일',
              '금요일',
              '토요일',
            ],
            shortDays: ['일', '월', '화', '수', '목', '금', '토'],
          },
        },
      ],
      type: 'line',

      zoom: {
        enabled: false,
      },
    },
    colors: ['#17A2B8', 'rgba(40, 167, 69, 0.3)', 'rgba(239, 170, 45, 0.3)'],
    stroke: {
      width: [3, 0, 0],
      curve: 'smooth',
    },
    plotOptions: {
      bar: {
        columnWidth: '60%',
        rangeBarOverlap: true,
      },
    },
    xaxis: {
      type: 'datetime',
      labels: {
        show: true,
        datetimeFormatter: {
          year: 'yy년',
          month: 'MM월',
        },
        style: {
          fontSize: '13px',
          fontWeight: '400',
        },
      },
      axisBorder: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: [
      {
        seriesName: '전세평균',
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        labels: {
          formatter: (val) => {
            return `${val.toFixed(1)}억`;
          },
          style: {
            fontSize: '13px',
            fontWeight: '400',
          },
        },
        tickAmount: 4,
      },
      {
        seriesName: '전세 거래수',
        opposite: true,
        showForNullSeries: true,
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        labels: {
          formatter: (val) => {
            return `${val.toFixed(0)}건`;
          },
          style: {
            fontSize: '13px',
            fontWeight: '400',
          },
        },
      },
      {
        seriesName: '전세 거래수',
        show: false,
      },
    ],
    markers: {
      hover: {
        size: 5,
        sizeOffset: 5,
      },
    },
    noData: {
      text: '표시할 데이터가 없습니다',
    },
    tooltip: {
      x: {
        show: false,
      },
      custom: ({ series, seriesIndex, dataPointIndex, w }) => {
        return `
            <div class='tooltip-component'>
            <span class="tooptip-text">${w.globals.initialSeries[
              seriesIndex
            ].data[dataPointIndex].x.slice(2, 4)}.${w.globals.initialSeries[
          seriesIndex
        ].data[dataPointIndex].x.slice(5, 7)} &nbsp;</span>
              <span class="tooptip-text">평균 ${
                series[0][dataPointIndex]
              }억</span>&nbsp; 
              <span class="tooptip-text">(${series[1][dataPointIndex]})건</span>
            </div>
          `;
      },
      followCursor: true,
    },
    legend: {
      fontSize: '13px',
      show: true,
      position: 'top',
      horizontalAlign: 'left',
      itemMargin: {
        horizontal: innerWidth <= 280 ? 8 : 12,
      },
      offsetY: -3,
      markers: {
        width: 8,
        height: 8,
        offsetX: -2,
      },
      labels: {
        colors: '#888888',
      },
    },
  };

  return <ReactApexChart options={options} series={series} height='100%' />;
};

export default MixedCharts;
