import { atom } from 'recoil';

export const selectLive = atom({
  key: 'selectLive',
  default: 'm',
});

export const selectPyeong = atom({
  key: 'selectPyeong',
  default: '',
});

export const selectTab = atom({
  key: 'selectTab',
  default: 'recent',
});

export const modalState = atom({
  key: 'modalState',
  default: false,
});

export const currentRealEstateData = atom({
  key: 'realEstateData',
  default: [],
});

export const realEstateModalState = atom({
  key: 'realEstateModalState',
  default: false,
});

export const aptInfoDataList = atom({
  key: 'aptInfo',
  default: [],
});

export const recentDataList = atom({
  key: 'recentData',
  default: [],
});

export const chartDataList = atom({
  key: 'chartData',
  default: [],
});

export const loadingState = atom({
  key: 'loading',
  default: true,
});
