export const aptInformationApi = async () =>
  await fetch('comExec/realestate/?action=apt_info').then((res) => res.json());

export const tradeListApi = async (currentMenu, currentPyeong) =>
  await fetch(
    `comExec/realestate/?action=apt_area_stat&type=${currentMenu}&py=${currentPyeong}`,
  ).then((res) => res.json());

export const realTransactionApi = async ({
  currentMenu,
  pyeong,
  year = 'all',
  page = 1,
}) => {
  await fetch(
    `comExec/realestate/?action=apt_price&type=${currentMenu}&py=${pyeong}&year=${year}&page=${page}&row_count=5`,
  ).then((res) => res.json());
};

export const realEstate = {
  getData: async () => {
    return await fetch(`comExec/realestate/provider/index.php`)
      .then((res) => res.json())
      .catch((err) => err)
      .then((res) => res);
  },

  registerData: async () => {
    return await fetch('comExec/realestate/provider/index.php', {
      method: 'POST',
      body: {
        action: 'create',
      },
      headers: {
        accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((res) => res.json())
      .catch((err) => err)
      .then((res) => res);
  },

  modifyData: async ({ formData, id }) => {
    return await fetch('comExec/realestate/provider/index.php', {
      method: 'post',
      body: formData,
      headers: {
        accept: 'application/json',
        // 'Content-Type': 'multipart/form-data',
      },
    })
      .then((res) => res.json())
      .catch((err) => err)
      .then((res) => res);
  },

  removeData: async (id) => {
    return await fetch('comExec/realestate/provider/index.php', {
      method: 'post',
      body: JSON.stringify({
        action: 'delete',
        id,
      }),
      headers: {
        accept: 'application/json',
      },
    })
      .then((res) => res.json())
      .catch((err) => err)
      .then((res) => res);
  },
};
